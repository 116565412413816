import { gql } from "@apollo/client";

const UPDATE_FINANCES_SUPPORT = gql`
  mutation UpdateFinancesSupport(
    $where: FinancesWhereTaxYearInput!
    $data: UpdateFinancesSupport!
  ) {
    updateFinancesSupport(where: $where, data: $data) {
      id
      taxYear
      unemployedSince
      supportCalculationScenario
      supportCalculation {
        id
        content
      }
    }
  }
`;

export default UPDATE_FINANCES_SUPPORT;
