import { gql } from "@apollo/client";

const ALL_COURTS = gql`
  query allCourts($where: CourtFilter) @api(name: "sanity") {
    allCourt(where: $where) {
      _id
      name
      description
      default
      locations {
        _id
        name
        street1
        street2
        postal
        city {
          name
        }
        province {
          name
        }
        judicialDistrict {
          name
        }
      }
      province {
        _id
        shorthand
      }
    }
  }
`;

export default ALL_COURTS;
