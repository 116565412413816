import React, { useEffect, useState } from "react";

import { useFormikContext } from "formik";

import Select from "components/common/inputs/Select/Select";
import TextInput from "components/common/inputs/TextInput";
import { UPGRADE_PLAN_MODAL_NAME } from "components/modals/upgrade/UpgradePlanModal";

import useMe from "hooks/useMe";
import useModal from "hooks/useModal";

export const options = [
  {
    label: "Standard Scenarios",
    value: "standardScenarios",
    valuesToSet: {
      agreedSpousalSupportType: "standardScenarios",
      agreedSpousalSupport: -1,
      showRecipientNDI: false,
      recipientNDI: 0,
      showRecipientNDIAmount: false,
      recipientNDIAmount: 0,
      payorNDIAmount: 0,
      showTargetAmount: false,
    },
  },
  {
    label: "Specify Monthly Spousal Payment",
    value: "specifyMonthlySpousalPayment",
    valuesToSet: {
      agreedSpousalSupportType: "specifyMonthlySpousalPayment",
      agreedSpousalSupport: 0,
      showTargetAmount: true,
    },
  },
  {
    label: "No Spousal Payment",
    value: "noSpousalPayment",
    valuesToSet: {
      agreedSpousalSupportType: "noSpousalPayment",
      agreedSpousalSupport: 0,
      showRecipientNDI: false,
      recipientNDI: 0,
      showRecipientNDIAmount: false,
      recipientNDIAmount: 0,
      payorNDIAmount: 0,
      showTargetAmount: false,
    },
  },
  {
    label: "50/50 NDI Split",
    value: "NDISplit",
    valuesToSet: {
      agreedSpousalSupportType: "NDISplit",
      showRecipientNDI: true,
      recipientNDI: 0.5,
      showTargetAmount: false,
    },
  },
  {
    label: "Specify Recipient NDI %",
    value: "specifyRecipientNDI",
    valuesToSet: {
      agreedSpousalSupportType: "recipientNDI",
      showRecipientNDI: true,
      recipientNDI: 0,
      showTargetAmount: false,
    },
  },
  {
    label: "Specify Monthly Recipient NDI Amount",
    value: "specifyMonthlyRecipient",
    valuesToSet: {
      agreedSpousalSupportType: "monthlyNDISplit",
      showRecipientNDIAmount: true,
      recipientNDIAmount: 0,
      showTargetAmount: false,
    },
  },
  {
    label: "Specify Monthly Payor NDI Amount",
    value: "specifyMonthlyPayor",
    valuesToSet: {
      agreedSpousalSupportType: "monthlyNDIAmount",
      showPayorNDIAmount: true,
      payorNDIAmount: 0,
      showTargetAmount: false,
    },
  },
];

const getDefaultAgreedSpousalSupportType = (values) => {
  let type;

  if (
    values.agreedSpousalSupport === 0 &&
    (values?.agreedSpousalSupportType === "noSpousalPayment" || !values?.agreedSpousalSupportType)
  ) {
    return "noSpousalPayment";
  }

  if (
    values.agreedSpousalSupport >= 0 &&
    values?.agreedSpousalSupportType === "specifyMonthlySpousalPayment"
  ) {
    return "specifyMonthlySpousalPayment";
  }

  if (values.showRecipientNDI && values.recipientNDI === 0.5) {
    return "NDISplit";
  }

  if (values.showRecipientNDI && values.recipientNDI !== void 0) {
    return "specifyRecipientNDI";
  }

  if (values.showRecipientNDI && values.payorNDIAmount !== void 0) {
    return "specifyRecipientNDI";
  }

  if (values.showRecipientNDIAmount && values.recipientNDIAmount !== void 0) {
    return "specifyMonthlyRecipient";
  }

  if (values.showPayorNDIAmount && values.payorNDIAmount !== void 0) {
    return "specifyMonthlyPayor";
  }

  if (values.agreedSpousalSupport === -1) {
    return "standardScenarios";
  }

  return type;
};

const AgreedSpousalSupport = () => {
  const { open: openUpgradeModal } = useModal(UPGRADE_PLAN_MODAL_NAME);
  const { setFieldValue, setFieldTouched, values } = useFormikContext();
  const { isActiveSub } = useMe();

  const [agreedSpousalSupport, setAgreedSpousalSupport] = useState(
    getDefaultAgreedSpousalSupportType(values),
  );

  useEffect(() => {
    setAgreedSpousalSupport(getDefaultAgreedSpousalSupportType(values));
  }, [values]);

  const defaultValues = {
    agreedSpousalSupport: -1,
    showRecipientNDI: void 0,
    showRecipientNDIAmount: void 0,
    showPayorNDIAmount: void 0,
    recipientNDI: void 0,
    recipientNDIAmount: void 0,
    payorNDIAmount: void 0,
  };

  return (
    <React.Fragment>
      <Select
        width="unset"
        containerClassName="mb-3"
        label="Customize Support Scenarios"
        hint="Select a custom spousal support scenario to display in addition to the standard low/mid/high ranges pursuant to the Spousal Support Advisory Guidelines"
        disabled={!isActiveSub}
        value={agreedSpousalSupport}
        onChange={(value) => {
          setAgreedSpousalSupport(value.value);

          const valuesToSet = {
            ...defaultValues,
            ...value.valuesToSet,
          };

          Object.keys(valuesToSet).forEach((key) => {
            setFieldValue(key, valuesToSet[key]);
            setFieldTouched(key, true);
          });
        }}
        options={options}
      />

      {values?.agreedSpousalSupport >= 0 &&
        values?.agreedSpousalSupportType !== "noSpousalPayment" && (
          <TextInput
            min="0"
            disabled={!isActiveSub}
            disabledOnClick={openUpgradeModal}
            allowZero
            name="agreedSpousalSupport"
            label="Agreed Spousal Support"
            hint="Enter the **monthly** agreed amount for spousal support, or 0 for no spousal support."
            prepend="dollar"
            type="number"
          />
        )}

      {agreedSpousalSupport === "specifyRecipientNDI" && (
        <TextInput
          min="0"
          step="any"
          disabled={!isActiveSub}
          disabledOnClick={openUpgradeModal}
          allowZero
          name="recipientNDI"
          label="Recipient NDI %"
          prepend="percent"
          type="number"
          hint="Show a custom scenario where the recipient of support receives this percentage of combined net disposable income."
        />
      )}

      {agreedSpousalSupport === "specifyMonthlyRecipient" && (
        <TextInput
          min="0"
          disabled={!isActiveSub}
          disabledOnClick={openUpgradeModal}
          allowZero
          name="recipientNDIAmount"
          label="Monthly Recipient NDI Amount"
          prepend="dollar"
          type="number"
          hint="Show a custom scenario where the recipient of support receives this amount of net disposable income, including support."
        />
      )}

      {agreedSpousalSupport === "specifyMonthlyPayor" && (
        <TextInput
          min="0"
          disabled={!isActiveSub}
          disabledOnClick={openUpgradeModal}
          allowZero
          name="payorNDIAmount"
          label="Monthly Payor NDI Amount"
          prepend="dollar"
          type="number"
          hint="Show a custom scenario where the payor of support is left with this amount of net disposable income, after paying support, taxes, etc."
        />
      )}

      {agreedSpousalSupport === "NDISplit" && (
        <small className="form-group form-text text-muted mt-4">
          Show a custom scenario where each party receives 50% of combined net disposable income.
        </small>
      )}

      {agreedSpousalSupport === "noSpousalPayment" && (
        <small className="form-group form-text text-muted mt-4">
          Show a custom scenario with no spousal support payment, alongside the standard ranges.{" "}
        </small>
      )}
    </React.Fragment>
  );
};

export default AgreedSpousalSupport;
