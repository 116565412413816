/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useState, useEffect, useMemo } from "react";

import { useMutation, useQuery } from "@apollo/client";
import { FormikProvider, useFormik } from "formik";
import { isEmpty, omit } from "lodash";
import { toast } from "react-toastify";
import { Form, Row, Col } from "reactstrap";
import * as yup from "yup";

import useCreateLawyer from "graphql/hooks/lawyer/useCreateLawyer";
import useUpdateLawyer from "graphql/hooks/lawyer/useUpdateLawyer";
import UPDATE_CLIENT from "graphql/mutations/clients/updateClient";
import ALL_LAWYERS from "graphql/sanity/allLawyers";

import LawyerSelectOption from "components/ClientProfile/forms/counsel/LawyerSelectOption";
import getInitialValues from "components/ClientProfile/forms/counsel/getInitialValues";
import formInputs from "components/ClientProfile/forms/counsel/inputs";
import difference from "components/calculations/utils/difference";
import { provinceOptions } from "components/calculations/utils/regionNames";
import Button from "components/common/Button";
import ToggleButtons from "components/common/ToggleButtons";
import CreatableSelect from "components/common/inputs/Select/CreatableSelect";
import SelectField from "components/common/inputs/Select/SelectField";
import TextInput from "components/common/inputs/TextInput";

import useCourtInformation from "hooks/me/useCourtInformation";
import useConfirm from "hooks/useConfirm";

import assignObjectValuesToSet from "utils/assignObjectValuesToSet";
import getVariablesForAllLawyersQuery from "utils/lawyers/getVariablesForAllLawyersQuery";

const validationSchema = yup.object().shape({
  location: yup.object().shape({
    email: yup.string().email("Invalid email address"),
  }),
});

const Counsel = ({ user, onSubmit }) => {
  const [input, setInput] = useState("");

  const { provinceShorthand: residence } = useCourtInformation(user?.client);

  const { data } = useQuery(ALL_LAWYERS, {
    variables: getVariablesForAllLawyersQuery({ residence }),
  });

  const exLawyer = useMemo(() => {
    if (!user?.client?.exLawyer) return null;

    return {
      ...user?.client?.exLawyer,
      sanityId: user?.client?.exLawyer?.sanityId || "non-sanity",
    };
  }, [user?.client?.exLawyer]);

  const lawyersOptions = useMemo(() => {
    const lawyers = [...(data?.allLawyer || [])];

    if (exLawyer?.sanityId === "non-sanity") lawyers.unshift({ name: exLawyer?.name });

    const mappedLawyers = lawyers.map((lawyer) => ({
      label: lawyer?.name,
      value: lawyer?._id || "non-sanity",
      city: lawyer?.city,
      orgName: lawyer?.orgName,
    }));

    const filteredLawyers = mappedLawyers.filter((lawyer) =>
      lawyer?.label?.toLowerCase()?.includes?.(input.toLowerCase()),
    );

    return filteredLawyers.slice(0, 50);
  }, [data?.allLawyer, exLawyer?.name, exLawyer?.sanityId, input]);

  const [updateClient, { loading: clientLoading }] = useMutation(UPDATE_CLIENT);

  const [createLawyer, { loading: createLoading }] = useCreateLawyer(user?.client);
  const [updateLawyer, { loading: updateLoading }] = useUpdateLawyer(user?.client);

  const [initialValues, setInitialValues] = useState(getInitialValues(exLawyer));

  const confirm = useConfirm({
    size: "md",
    title: "Update lawyer?",
    message: "Update this lawyer's contact information in the database?",
    negativeBtnLabel: "No Thanks",
    positiveBtnLabel: "Submit Change",
    reversedButtons: true,
  });

  const handleSubmitForm = useCallback(
    async (values, { setSubmitting }) => {
      const formInitialValues = getInitialValues(exLawyer);

      const differenceData = difference(values, formInitialValues);

      try {
        if (!isEmpty(differenceData)) {
          setSubmitting(true);

          const { location, name, orgName, sanityId, isSelfRepresented } = values;

          const updatedLawyer = {
            _id: sanityId,
            name,
            orgName,
            // description:
            fax: location?.fax || null,
            email: location?.email || "",
            phone: location?.phone || "",
            postal: location?.postal || "",
            city: location?.city || "",
            residence: location?.residence ? { shorthand: location?.residence } : null,
            country: location?.country || "",
            street2: location?.street2 || "",
            street1: location?.street1 || "",
          };

          const lawyer = data?.allLawyer?.find((lawyer) => lawyer?._id === sanityId);

          const ommitedLawyer = omit(
            lawyer,
            "__typename",
            "residence.name",
            "residence.__typename",
            "residence._id",
          );

          const isLawyerChanged = !isEmpty(difference(ommitedLawyer, updatedLawyer));

          if (isLawyerChanged) {
            if (
              await confirm({
                size: "md",
                title: "Update lawyer?",
                message: "Update this lawyer's contact information in the database?",
                negativeBtnLabel: "No Thanks",
                positiveBtnLabel: "Submit Change",
                reversedButtons: true,
              })
            ) {
              const isUpdate = exLawyer?.sanityId === sanityId;
              const func = isUpdate ? updateLawyer : createLawyer;

              func({
                ...(isUpdate
                  ? assignObjectValuesToSet({
                      name,
                      orgName,
                      sanityId,
                    })
                  : { name, orgName, sanityId }),
                location: isUpdate
                  ? { create: location, update: assignObjectValuesToSet(location) }
                  : location,
              });
            }
          }

          const locationData = {
            upsert: {
              create: location,
              update: assignObjectValuesToSet(location),
            },
          };

          const exLawyerData = { name, orgName, sanityId };

          const variables = {
            where: { id: user?.client?.id },
            data: {
              exProfile: {
                update: {
                  data: {
                    isSelfRepresented: {
                      set: isSelfRepresented,
                    },
                  },
                },
              },
              exLawyer: {
                upsert: {
                  create: {
                    ...exLawyerData,
                    location: {
                      create: location,
                    },
                  },
                  update: assignObjectValuesToSet({
                    ...exLawyerData,
                    location: locationData,
                  }),
                },
              },
            },
          };

          await updateClient({ variables });

          toast.success("Opposing counsel information has been successfully changed");
        }
      } catch (err) {
        console.error(err);
        err.graphQLErrors.map(({ message }) => toast.error(message));
      } finally {
        setSubmitting(false);
        onSubmit?.();
      }
    },
    [
      exLawyer,
      data?.allLawyer,
      user.client,
      updateClient,
      confirm,
      updateLawyer,
      createLawyer,
      onSubmit,
    ],
  );

  const formik = useFormik({
    initialValues: {
      ...initialValues,
      isSelfRepresented: user?.client?.exProfile?.isSelfRepresented || false,
    },
    onSubmit: handleSubmitForm,
    validationSchema,
    validateOnBlur: false,
    enableReinitialize: true,
  });

  const { handleSubmit, setFieldValue, values } = formik;

  const { sanityId } = values;

  const currentLawyer = useMemo(() => {
    if (exLawyer && sanityId === exLawyer.sanityId) return exLawyer;
    if ((!sanityId || sanityId === "non-sanity") && exLawyer?.sanityId === "non-sanity") {
      return exLawyer;
    }

    return data?.allLawyer?.find((lawyer) => lawyer?._id === sanityId);
  }, [data?.allLawyer, exLawyer, sanityId]);

  const currentLawyerOption = {
    value: sanityId,
    label: currentLawyer?.name,
    city: currentLawyer?.city,
    orgName: currentLawyer?.orgName,
  };

  const handleChange = ({ value }) => {
    setFieldValue("sanityId", value);
  };

  const handleCreate = async (name) => {
    await createLawyer({ name });
  };

  useEffect(() => {
    if (!exLawyer?.sanityId !== sanityId && currentLawyer && sanityId !== exLawyer?.sanityId) {
      setInitialValues(getInitialValues(currentLawyer));
    }

    if (sanityId === "non-sanity") {
      setInitialValues(getInitialValues(currentLawyer));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sanityId, currentLawyer, lawyersOptions]);

  useEffect(() => {
    if (sanityId === exLawyer?.sanityId) {
      setInitialValues(getInitialValues(currentLawyer));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lawyersOptions, sanityId]);

  useEffect(() => {
    if (exLawyer?.sanityId === "non-sanity") {
      setInitialValues(getInitialValues({ ...currentLawyer, sanityId: "non-sanity" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disabled = clientLoading || createLoading || updateLoading;

  return (
    <FormikProvider value={formik}>
      <Form className="counsel-form" onSubmit={handleSubmit}>
        <ToggleButtons
          label="Is the Opposing Party Self-Represented?"
          name="isSelfRepresented"
          value={values.isSelfRepresented}
          buttons={[
            {
              value: true,
              label: "Self-Represented",
            },
            {
              value: false,
              label: "Has Counsel",
            },
          ]}
          className="mt-3"
          onChange={(value) => setFieldValue("isSelfRepresented", value)}
        />

        {!values.isSelfRepresented && (
          <React.Fragment>
            <CreatableSelect
              label="Name"
              placeholder="Select or Create a Lawyer"
              containerClassName="mb-3"
              key="sanityId"
              name="sanityId"
              value={sanityId ? currentLawyerOption : null}
              handleChange={handleChange}
              onCreate={handleCreate}
              disabled={disabled}
              options={lawyersOptions}
              components={{ Option: LawyerSelectOption }}
              handleInputChange={(newInput) => setInput(newInput)}
            />
            {formInputs?.map((input, index) => {
              if (Array.isArray(input)) {
                return (
                  <Row key={index}>
                    {input.map((inputEl) => (
                      <Col key={inputEl.name} xs={6}>
                        <TextInput {...inputEl} disabled={disabled} />
                      </Col>
                    ))}
                  </Row>
                );
              }

              if (input.name === "location.residence") {
                return (
                  <Row key="location.residence">
                    <Col>
                      <SelectField
                        {...input}
                        disabled={disabled}
                        options={provinceOptions.filter((p) => p.value !== "CDN")}
                      />
                    </Col>
                  </Row>
                );
              }

              return (
                <Row key={input.name}>
                  <Col>
                    <TextInput {...input} disabled={disabled} />
                  </Col>
                </Row>
              );
            })}
          </React.Fragment>
        )}

        <div className="d-flex justify-content-between align-items-end buttons">
          <Button type="submit" size="lg" leftFAIcon="check" disabled={disabled}>
            Save & Submit
          </Button>
        </div>
      </Form>
    </FormikProvider>
  );
};

export default Counsel;
