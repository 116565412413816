import gql from "graphql-tag";
import CHILDREN_FRAGMENT from "graphql/fragments/children";
import EX_LAWYER from "graphql/fragments/exLawyer";
import FINANCES from "graphql/fragments/finances";
import INCOME_EARNERS from "graphql/fragments/incomeEarners";
import INTAKE_FORM from "graphql/fragments/intakeForm";
import ORGANIZATION_ISSUE from "graphql/fragments/organization/organizationIssue";
import PROFILE_FRAGMENT from "graphql/fragments/profile";
import RELATIONSHIP from "graphql/fragments/relationship";

import POSSESSION_FRAGMENT from "./possession";

const CLIENT_FRAGMENT = gql`
  ${RELATIONSHIP}
  ${PROFILE_FRAGMENT}
  ${CHILDREN_FRAGMENT}
  ${INTAKE_FORM}
  ${ORGANIZATION_ISSUE}
  ${FINANCES}
  ${INCOME_EARNERS}
  ${EX_LAWYER}
  ${POSSESSION_FRAGMENT}
  fragment CLIENT_FRAGMENT on Client {
    id
    updatedAt
    createdAt
    remoteSigningEnabled
    supportCalculations {
      id
      updatedAt
      createdAt
      content
      shares {
        id
        email
        status
      }
    }
    childExpenses {
      id
      clientId
      childId
      type
      totalDeductions
      total
      netExpense
      amountByClient
      amountByEx
    }
    otherIncomeEarners {
      ...INCOME_EARNERS
    }
    exOtherIncomeEarners {
      ...INCOME_EARNERS
    }
    profile {
      ...PROFILE_FRAGMENT
    }
    exProfile {
      ...PROFILE_FRAGMENT
    }
    relationship {
      ...RELATIONSHIP
    }
    children {
      ...CHILDREN
    }
    address {
      city
      country
      postal
      residence
      street1
      street2
    }
    exAddress {
      city
      country
      postal
      residence
      street1
      street2
    }
    parties {
      id
      type
      firstName
      lastName
      middleName
      phone
      email
      isSelfRepresented
      address {
        id
        city
        country
        postal
        residence
        street1
        street2
      }
      legalCounsel {
        ...EX_LAWYER
      }
      createdAt
    }
    professionals {
      id
      profile {
        id
        firstName
        middleName
        lastName
        email
      }
    }
    profileProgress {
      background
      address
      children
      relationship
      exBackground
      exAddress
    }
    intakeForm {
      ...INTAKE_FORM
    }
    interviews {
      id
      token
      status
      type
      intakeInterview {
        id
      }
      satisfactionInterview {
        id
      }
      createdAt
      updatedAt
    }
    documents {
      id
    }
    legalIssues {
      ...ORGANIZATION_ISSUE
    }
    type
    exLawyer {
      ...EX_LAWYER
    }
    income {
      id
      job {
        id
        name
        since
      }
      amount
      type
      taxYear
    }
    exIncome {
      id
      job {
        id
        name
        since
      }
      amount
      type
      taxYear
    }
    adjustments {
      id
      type
      amount
      taxYear
    }
    exAdjustments {
      id
      type
      amount
      taxYear
    }
    budgetItems {
      id
      category
      description
      amount
    }
    exBudgetItems {
      id
      category
      description
      amount
    }
    possessions {
      ...POSSESSION
    }
    customBudgetCategories
    finances {
      ...FINANCES
    }
    exFinances {
      ...FINANCES
    }
    court {
      id
      provinceShorthand
      courtFile
      courtId
      courtLocationId
    }
    isPlaintiff
    isApplicant
    proLocation {
      id
      residence
      country
      city
    }
    story
    primaryParentingRole
    clientPriorities
    exLabel
    clientLabel
  }
`;

export default CLIENT_FRAGMENT;
