import React, { useMemo } from "react";

import { useQuery } from "@apollo/client";

import ALL_COURTS from "graphql/sanity/allCourts";

import Select from "./Select";
import SelectField from "./SelectField";

/**
 * @property {string} residence - "Shorthand" for residence
 * @property {string} name - By default "courtId", can be changed
 * @property {string} placeholder - By default "Select default label", can be changed
 * @property {boolean} isFormikField - By default "false", responsible for what to use "SelectField" or "Select"
 */
const CourtSelect = ({
  provinceShorthand,

  name = "courtId",
  placeholder = "Select Court",

  disabled,
  isLoading,

  isFormikField = false,

  ...props
}) => {
  const { data: courtsData, loading: courtsLoading } = useQuery(ALL_COURTS, {
    variables: { where: { province: { shorthand: { eq: provinceShorthand } } } },
    skip: !provinceShorthand,
  });

  const options = useMemo(() => {
    return (courtsData?.allCourt || []).map((item) => ({
      id: item._id,
      value: item._id,
      label: item.name,
    }));
  }, [courtsData?.allCourt]);

  const selectProps = useMemo(() => {
    return {
      name,
      placeholder,
      options,
      disabled: !provinceShorthand || disabled,
      isLoading: courtsLoading || isLoading,
      ...props,
    };
  }, [courtsLoading, disabled, isLoading, name, options, placeholder, props, provinceShorthand]);

  if (isFormikField) {
    return <SelectField {...selectProps} />;
  }

  return <Select {...selectProps} />;
};

export default CourtSelect;
