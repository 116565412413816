const getVariablesForAllLawyersQuery = ({ residence } = {}) => {
  const variables = {
    where: {},
    sort: [{ name: "ASC" }],
  };

  if (residence) {
    variables.where.residence = {
      shorthand: {
        in: [residence],
      },
    };
  }

  return variables;
};

export default getVariablesForAllLawyersQuery;
