import React, { useMemo } from "react";

import { components } from "react-select";

const LawyerSelectOption = ({ children, ...props }) => {
  const { city, orgName, residence } = props.data || {};

  const trimmedOrgName = useMemo(() => {
    if (residence && orgName && orgName.length > 40) {
      return `${orgName.slice(0, 30)}...`;
    }

    return orgName;
  }, [orgName, residence]);

  return (
    <components.Option {...props}>
      <div className="custom-option">
        <p className="option-text text-limit mb-0">{children}</p>
        {(city || orgName) && (
          <div className="option-description">
            {residence && <span>{residence}</span>}
            <span>{city}</span>
            {trimmedOrgName && <span>{trimmedOrgName}</span>}
          </div>
        )}
      </div>
    </components.Option>
  );
};

export default LawyerSelectOption;
