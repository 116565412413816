import gql from "graphql-tag";

const PROFILE_FRAGMENT = gql`
  fragment PROFILE_FRAGMENT on Profile {
    id
    firstName
    lastName
    middleName
    gender
    phone
    email
    birthDate
    avatarUrl
    matter {
      id
      number
      description
    }
    birthName
    birthPlace
    occupation
    previousNames
    medicalIssues
    residence
    hasNewPartner
    partnerIncome
    northernResident
    ruralResident
    disabled
    childSupportStatus
    spousalSupportStatus
    parentingRole
    equalizationPayment
    matrimonialHome
    transferringPension
    valuationDate
    isSelfRepresented
  }
`;

export default PROFILE_FRAGMENT;
